import { Box, Button, Flex, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { getUrlProps } from "helpers/landing"

import Image from "./image"

const Container = styled(Box).attrs((props) => ({
  display: "grid",
  gridGap: "6px",
  gridTemplateColumns: {
    _: "repeat(2, 1fr)",
    tablet: "repeat(4, 1fr)",
  },
  px: { _: "6px", tablet: "10px" },
  py: { _: "6px", tablet: "12px" },
  ...props,
}))``

const ItemContainer = styled(Flex).attrs((props) => ({
  color: "white",
  flexDirection: "column",
  height: "100%",
  justifyContent: "flex-end",
  left: "0",
  p: "12px",
  position: "absolute",
  top: "0",
  width: "100%",
  ...props,
}))``

const Title = styled(Typography).attrs((props) => ({
  as: "h2",
  fontSize: { _: "22px", desktop: "28px" },
  fontWeight: "700",
  lineHeight: { _: "24px", desktop: "30px" },
  textTransform: "uppercase",
  ...props,
}))``

const Subtitle = styled(Typography).attrs((props) => ({
  display: { _: "none", tablet: "block" },
  fontSize: { _: "18px", desktop: "20px" },
  fontWeight: "700",
  lineHeight: { _: "20px", desktop: "22px" },
  ...props,
}))``

const CTABox = styled(Box).attrs((props) => ({
  fontSize: { _: "16px", desktop: "18px" },
  lineHeight: "22px",
  ...props,
}))``

const CTA = styled(Button).attrs({
  variant: "text",
})`
  text-decoration: underline;
`

const ImageLink = styled(Button).attrs({
  variant: "text",
})`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;

  &:hover ~ ${ItemContainer} {
    ${CTA} {
      text-decoration: none;
    }
  }
`

const FeatureGrid = ({ element, lazyload }) => {
  return (
    <Container contain={element.contain}>
      {(element.data?.items || []).map((item, index) => {
        const urlProps = getUrlProps(
          item.url,
          null,
          element.anchor_name || element.id
        )

        return (
          <Box key={`${element.id}-${index}`} position="relative">
            <ImageLink aria-label={item.title} {...urlProps} />
            <Image image={item.image} lazyload={lazyload} />
            <ItemContainer {...item.style?.container_style}>
              <Title
                {...item.style?.title_style}
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
              {item.subtitle && (
                <Subtitle
                  {...item.style?.subtitle_style}
                  dangerouslySetInnerHTML={{ __html: item.subtitle }}
                />
              )}
              <CTABox {...item.style?.cta_style}>
                <CTA {...urlProps}>{item.cta}</CTA>
              </CTABox>
            </ItemContainer>
          </Box>
        )
      })}
    </Container>
  )
}

FeatureGrid.propTypes = {
  element: PropTypes.object,
  lazyload: PropTypes.bool,
}

export default FeatureGrid
